import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import Reputation from './reputation'
import WhyUs from './whyus'
import ContactInfo from './contact-info'
import ReviewsSlider from './reviews-slider'
import AwardsCertsSlider from './awards-certs'
import CardGeneral from './card-general'

const MainContainerOuter = styled.div`
  width: 100%;
  background: white;
  margin-bottom: 1em;
`

const SidebarTitle = styled.div`
  background: #f6f6f6;
  padding: 1em;
  h3 {
    margin: 0;
  }
`
const SidebarContent = styled.div`
  padding: 1em;
`

const Card = ({ sidebar, children }) => {
  if (sidebar.content.content.includes('{{AwardsCerts}}')) {
    return (
      <CardGeneral title={'Certifications'} className="remove-padding-shadow">
        <AwardsCertsSlider />
      </CardGeneral>
    )
  }
  if (sidebar.content.content.includes('{{ReputationBar}}')) {
    return (
      <CardGeneral title={'Social Media Profiles'}>
        <Reputation />
        <Link
          className="border-radius button-style-primary  text-base font-semibold mt-4 rounded-full flex flex-col items-center justify-between col-span-1 md:col-span-2 lg:col-span-1 py-2 px-3 shadow-md hover:shadow-lg hover:cursor-pointer cursor-pointer"
          to={'/reviews/all/'}
          state={{
            modal: true,
          }}
        >
          Read All Reviews
        </Link>
      </CardGeneral>
    )
  }
  if (sidebar.content.content.includes('{{Testimonials}}')) {
    return <ReviewsSlider />
  }
  if (sidebar.content.content.includes('{{WhyUs}}')) {
    return <WhyUs />
  }
  if (sidebar.content.content.includes('{{ContactInfo}}')) {
    return <ContactInfo />
  }
  if (sidebar.content.content.includes('blog'))
    return (
      <MainContainerOuter
        className={
          'border-radius shadow-md sidebar-card ' +
          (sidebar && sidebar.cssClasses ? sidebar && sidebar.cssClasses : '')
        }
      >
        <SidebarTitle className="moduleheading">
          <h4>{sidebar.title}</h4>
        </SidebarTitle>
        <SidebarContent className="sidebarstyle">{children}</SidebarContent>
      </MainContainerOuter>
    )

  return (
    <MainContainerOuter
      className={
        'border-radius shadow-md sidebar-card ' +
        (sidebar && sidebar.cssClasses ? sidebar && sidebar.cssClasses : '')
      }
    >
      <SidebarTitle className="moduleheading">
        <h4>{sidebar.title}</h4>
      </SidebarTitle>
      <SidebarContent
        className="sidebarcontent"
        dangerouslySetInnerHTML={{ __html: sidebar.content.content }}
      />
    </MainContainerOuter>
  )
}

export default Card
